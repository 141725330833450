/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
/* global _paq */

import React, { useState, useEffect, useContext, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withPrefix } from "gatsby"
import ReactJWPlayer from "react-jw-player"
import "typeface-work-sans"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Header from "./header"
import "../styles/main.scss"
import NavBar from "./navBar"
import {
  TOKEN_EXPIRED,
  PLAYER_EVENT_PLAY,
  PLAYER_EVENT_PAUSE,
  PRESENTATION_STATUS_LIVE,
} from "../utils/constants"
import { isLoggedIn, clearClientData } from "../services/auth"
import { getNestedObjectValue } from "../utils/global"
import Notification from "./shared/notification"
import { miniPlayerContext } from "../context/miniPlayer"
import { livePlayerContext } from "../context/livePlayer"
import { onDemandPlayerContext } from "../context/onDemandPlayer"
import { isMatomoEnabled } from '../data/global';

import { playerEvent } from "../utils/analytics"
import { SocketContext } from "../context/socket";
import { handleJoinLiveViewer, handleRemoveLiveViewer } from '../utils/socket/socketOperations';
const Layout = ({ children }) => {
  const [miniPlayerState, setminiPlayerState] = useContext(miniPlayerContext);
  const [livePlayerState, setlivePlayerState] = useContext(livePlayerContext);
    const [onDemandPlayerState, setOnDemandPlayerState] = useContext(onDemandPlayerContext);
  const [eventTitle, setEventTitle] = useState("")
  const [activeSlideID, setActiveSlideID] = useState("");
  const [notification, setNotification] = useState("");
  const [notificationTime, setNotificationTime] = useState("");
  const [liveStream, setLiveStream] = useState(false);

  const { socket, isConnected } = useContext(SocketContext);
  const fetchEventDetails = async () => {
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/getEventDetails`
    )
    let data = await response.json()
    let event = getNestedObjectValue(data, "result.event")
    let eventTitle = event && event.title ? event.title : ""
    if (event && event.rocketChat) {
      window.localStorage.hasNetworking = Boolean(event.rocketChat.hasNetworking);
      window.localStorage.isNetworkingEnabled = Boolean(event.rocketChat.isNetworkingEnabled);
    } else {
      window.localStorage.hasNetworking = false;
      window.localStorage.isNetworkingEnabled = false;
    }
    setEventTitle(eventTitle)
  }

  const onError = async ({ code }) => {
    if (code === 232011 || code === 232001) {
      let response = await fetch(
        `${process.env.API_URL}/${process.env.EVENT_ID}/live`
      )
      response = await response.json()
      if (response && response.status === 200 && response.result == null) {
        if (miniPlayerState && miniPlayerState.eventType === PLAYER_EVENT_PLAY) {
          onPause()
        }
        miniplayerClose();
      }
    }
  }

  const onTime = () => {
    if (window.location.pathname.includes("live") && activeSlideID) {
      setminiPlayerState({...miniPlayerState, activeSlideFromMP:miniPlayerState});
    }
  }

  function onMeta(event) {
    if (
      event &&
      event.metadata &&
      (event.metadata.TIT2 || event.metadata.TIT1)
    ) {
      let slideChangeEvent
      if (event.metadata.TIT1) {
        slideChangeEvent = JSON.parse(event.metadata.TIT1.trim())
      } else if (event.metadata.TIT2) {
        slideChangeEvent = JSON.parse(event.metadata.TIT2.trim())
      }
      if (slideChangeEvent.eventType === "slideChange") {
        setminiPlayerState({...miniPlayerState, activeSlideFromMP:slideChangeEvent.value})
      }
    }
  }

  const getDataJson = async () => {
    let random = Math.random()
    let response = await fetch(`/data.json?q=${random}`)
    if (response && response.status === 200) {
      response = await response.json()
      if (response.livewebcast !== "") {
          setLiveStream(true);
      } else {
        miniplayerClose()
          setLiveStream(false)
      }

      //Checking notifications
      if (response.broadcast && response.broadcast.message && response.broadcast.date) {
        setNotification(response.broadcast.message);
        setNotificationTime(response.broadcast.date);
      } else {
        setNotification("");
        setNotificationTime("");
      }
    } else {
      miniplayerClose()
      if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
        clearClientData()
      }
    }
  }

  function onReady() {
    if(isMatomoEnabled){
      _paq.push(['MediaAnalytics::enableMediaAnalytics']);
      _paq.push(['MediaAnalytics::scanForMedia'])
    }
  }

  const miniplayerClose = () => {
    if (miniPlayerState && miniPlayerState.eventType === PLAYER_EVENT_PLAY) {
      onPause()
     }
    setminiPlayerState({ ...miniPlayerState, showVideoAtBottom: false });
    setPosition({
      x: 0,
      y: 0,
    });
  };

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [initialOffset, setInitialOffset] = useState({ x: 0, y: 0 });
  const draggableContainerRef = useRef(null);

  const moveElement = (clientX, clientY) => {
    const newX = clientX - initialOffset.x;
    const newY = clientY - initialOffset.y;
    setPosition({
      x: newX,
      y: newY,
    });
  };

  const startDragging = (clientX, clientY) => {
    const containerRect = draggableContainerRef.current.getBoundingClientRect();
    setIsDragging(true);
    setInitialOffset({
      x: clientX - containerRect.left,
      y: clientY - containerRect.top,
    });
  };

  const handleMouseDown = e => {
    startDragging(e.clientX, e.clientY);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    moveElement(e.clientX, e.clientY);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchMove = e => {
    e.preventDefault();
    if (!isDragging || e.touches.length !== 1) return;
    const touch = e.touches[0];
    moveElement(touch.clientX, touch.clientY);
  };

  const handleTouchStart = e => {
    e.preventDefault();
    const touch = e.touches[0];
    startDragging(touch.clientX, touch.clientY);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("touchmove", handleTouchMove, { passive: false });
      window.addEventListener("touchend", handleTouchEnd);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchmove", handleTouchMove, { passive: false });
      window.removeEventListener("touchend", handleTouchEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isDragging]);
    if (isLoggedIn() && typeof window !== 'undefined' && window.location.pathname) {
      const path = window.location.pathname;
      
      if (!path.includes("live") && livePlayerState?.eventType === PLAYER_EVENT_PLAY) {
        pausePlayer(livePlayerState);
      }
    
      // if (!path.includes("live") && !path.includes("resources")  && miniPlayerState?.eventType === PLAYER_EVENT_PLAY) {
      //   pausePlayer(miniPlayerState);
      // }
    
      if (!path.includes("resources") &&  onDemandPlayerState?.eventType === PLAYER_EVENT_PLAY) {
        pausePlayer(onDemandPlayerState);
      }
    }
    
    function pausePlayer(playerState) {
      playerEvent({
        eventType: PLAYER_EVENT_PAUSE,
        presentationId: playerState.presentationId,
        status: playerState.status,
      });
      if(isConnected){
        handleRemoveLiveViewer(socket,playerState.presentationId)
      }
    }

  function onPlay() {
    playerEvent({
      eventType: PLAYER_EVENT_PLAY,
      presentationId: miniPlayerState.presentationId,
      status: PRESENTATION_STATUS_LIVE,
    })
    setminiPlayerState({...miniPlayerState,
      eventType: PLAYER_EVENT_PLAY,
      presentationId: miniPlayerState.presentationId,
      status: PRESENTATION_STATUS_LIVE,
    });
    if(isConnected){
      handleJoinLiveViewer(socket,miniPlayerState.presentationId)
    }
  }

  function onPause() {
    playerEvent({
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: miniPlayerState.presentationId,
      status: PRESENTATION_STATUS_LIVE,
    })
    setminiPlayerState({...miniPlayerState,
      eventType: PLAYER_EVENT_PAUSE,
      presentationId: miniPlayerState.presentationId,
      status: PRESENTATION_STATUS_LIVE,
    });
    if(isConnected){
      handleRemoveLiveViewer(socket,miniPlayerState.presentationId)
    }
  }

  function onResume() {
   playerEvent({
      eventType: PLAYER_EVENT_PLAY,
      presentationId: miniPlayerState.presentationId,
      status: PRESENTATION_STATUS_LIVE,
    })
    setminiPlayerState({...miniPlayerState,
      eventType: PLAYER_EVENT_PLAY,
      presentationId: miniPlayerState.presentationId,
      status: PRESENTATION_STATUS_LIVE,
    });
    if(isConnected){
      handleJoinLiveViewer(socket,miniPlayerState.presentationId)
    }
  }

  const miniplayerUI = () => {
    return (
      <div
        className="pip"
        style={{transform: `translate3d(${position.x}px, ${position.y}px, 0)`}}
        ref={draggableContainerRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <div className="d-xs-flex xs-end">
          <FontAwesomeIcon
            icon={faTimes}
            className="pointer-cursor"
            onClick={miniplayerClose}
          />
        </div>
        <ReactJWPlayer
          playerId="pipLiveStream"
          playerScript={withPrefix("js/jwplayer-8.27.1/jwplayer.js")}
          file={miniPlayerState.videoUrl}
          onError={onError}
          onTime={onTime}
          onMeta={onMeta}
          licenseKey={process.env.JWPLAYER_LICENCE}
          onReady={onReady}
          onPlay={onPlay}
          onPause={onPause}
          onResume={onResume}
          customProps={{ title: miniPlayerState.videoTitle,pipIcon: "disabled" }}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchEventDetails();
  }, [])

  useEffect(() => {
    const checkDataJsonInterval = setInterval(() => {
      getDataJson()
    }, 5000)

    return () => clearInterval(checkDataJsonInterval)
  }, [miniPlayerState?.showVideoAtBottom, notification, notificationTime])

  return (
    <>
      {isLoggedIn() && miniPlayerState?.showVideoAtBottom && miniplayerUI()}

      <Header siteTitle={eventTitle} />
      <div className="main-wrapper d-sm-flex">
        {isLoggedIn() && <NavBar liveStream={liveStream} />} 
        <div className="content-wrapper d-sm-flex xs-between xs-column background-color-primary-1">

          {isLoggedIn() && <Notification message={notification} time={notificationTime} />}

          <main className="main pb-0 d-xs-flex xs-row height-full color-monochrome-1">
            {children}
          </main>

          <footer className="footer-block background-color-secondary-2">
            <div className="container-fluid">
              <ul className="footer-content d-sm-flex xs-end xs-middle width-full pl-0 mb-0 pt-15 pb-15">
                <li className="mr-30 mb-xs-20">
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/accessibility"
                  >
                    Accessibility
                  </Link>
                </li>
                <li className="mr-30 mb-xs-20">
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/terms-of-use"
                  >
                    Terms of use
                  </Link>
                </li>
                <li>
                  <Link
                    className="font-secondary-regular color-monochrome-1"
                    to="/cookie-policy"
                  >
                    Cookies
                  </Link>
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(Layout)
